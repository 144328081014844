import React from "react";

import { Switch, Route } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import amber from "@material-ui/core/colors/amber";
import Navbar from "./Navbar";
import Home from "../Home";
import Report from "../Report";
import Profile1 from "../Profile1";
import ListForm from "../ListForm";
import EmployeeManagement from "../EmployeeManagement";
import ClickupData from "../ClickUpData";
import CentralizedTicketing from "../CentralizedTicketing"
import EstimateTable from "../EstimateTable";
import MarketingManagement from "../MarketingManagement";
import MarketingManagementUpload from "../MarketingManagementUpload";
import ClickUpDataForm from "../ClickUpDataForm";
import PrivateRoutes from "../../Routes/PrivateRoutes";
import LocationTable from "../BiometricData";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,1)",
    margin: "0px 10px 0px",
    fontSize: "19px",
    marginTop: "0",
    marginBottom: "0",
  },
  small: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    backgroundColor: amber[300],
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  Media: {
    height: 58,
    width: "80%",
  },
  Main: {
    height: 58,
  },
  container: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  paperPop: {
    marginRight: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"© Copyright "} {new Date().getFullYear() + " "}
      <Link color="inherit" href="https://www.decimalpointanalytics.com/">
        Decimal Point Analytics
      </Link>{"."}
    </Typography>
  );
}

// common layout for all pages - the sidebar and topbar
export default function Base() {
  var jwt = document.cookie
    .split("; ")
    .find((row) => row.startsWith("C-X-ssoJwt="));
  if (jwt === undefined) {
    window.location = process.env.REACT_APP_SSO_LOGIN_URL;
  }

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Navbar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="xl" className={classes.container}>
          <Switch>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route path="/report">
              <Report />
            </Route>
            <Route path="/profile">
              <Profile1 />
            </Route>
            <Route path="/invoices-employees">
              <ListForm />
            </Route>
            <Route path="/employee-management">
              <EmployeeManagement />
            </Route>
            <Route path="/clickup-data">
              <ClickupData/>
            </Route>
            <Route path="/centralized-data">
              <CentralizedTicketing/>
            </Route>
            <Route path="/estimate-data">
              <EstimateTable/>
            </Route>
            <Route path="/clickup-form-data">
              <ClickUpDataForm/>
            </Route>
            <Route path="/marketing-data">
              <MarketingManagement/>
            </Route>
            <Route path="/marketing-data-upload">
              <MarketingManagementUpload/>
            </Route>
            <Route path="/biometric-data">
              <LocationTable/>
            </Route>
          </Switch>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
